import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';

// Importação dos componentes de forma lazy
const HomePage = React.lazy(() => import('./components/HomePage'));
const EditMessagePage = React.lazy(() => import('./components/EditMessagePage'));
const MessagePage = React.lazy(() => import('./components/MessagePage'));


const queryClient = new QueryClient();

function LoadingFallback() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center'
    }}>
      <div className="loading-overlay-vertical">
        <div className="spinner"></div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/criar" element={<EditMessagePage />} />
            <Route
              path="/recado/:id"
              element={
                <QueryClientProvider client={queryClient}>
                  <MessagePage />
                </QueryClientProvider>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
    </Router>
  );
}

export default App;
